<template>
  <v-row class="bbr-program-plan--form-deatils">
    <v-col cols="12" sm="3">
      <v-checkbox
        v-model="form.has_free_trial"
        label="Has Free Trial"
        color="primary"
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-checkbox
        v-model="form.is_featured"
        label="Is Featured"
        color="primary"
      />
    </v-col>

    <v-spacer />

    <v-col cols="12">
      <v-text-field
        v-model="form.name"
        label="Plan Name"
        :error-messages="form.$getError('name')"
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
        v-mask="'####'"
        v-model="form.week_count"
        label="Plan Week  Count"
        :error-messages="form.$getError('week_count')"
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" sm="4">
      <v-menu
        v-model="dateMenu1"
        :close-on-content-click="false"
        transition="scale-transition"
        min-width="220px"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :value="tzFormat(form.active_from)"
            :append-icon="icons.calendar"
            @click:clear="form.active_from = null"
            label="Active From"
            clearable
            readonly
            outlined
            flat
          />
        </template>
        <vc-date-picker
          ref="picker1"
          min="2020-01-01"
          v-model="form.active_from"
          mode="dateTime"
          :model-config="dateConfig"
          timezone="Australia/Brisbane"
        />
      </v-menu>
    </v-col>

    <v-col cols="12" sm="4">
      <v-menu
        v-model="dateMenu2"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="220px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :value="tzFormat(form.active_to)"
            label="Active To"
            :append-icon="icons.calendar"
            @click:clear="form.active_to = null"
            clearable
            readonly
            outlined
            flat
          />
        </template>
        <vc-date-picker
          ref="picker2"
          min="2020-01-01"
          v-model="form.active_to"
          mode="dateTime"
          :model-config="dateConfig"
          timezone="Australia/Brisbane"
        />
      </v-menu>
    </v-col>

    <v-col cols="12" sm="4">
      <v-menu
        v-model="dateMenu3"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="220px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :value="tzFormat(form.rollout_at)"
            label="Rollout Date"
            :append-icon="icons.calendar"
            @click:clear="form.rollout_at = null"
            clearable
            readonly
            outlined
            flat
          />
        </template>
        <vc-date-picker
          ref="picker3"
          min="2020-01-01"
          v-model="form.rollout_at"
          mode="dateTime"
          :model-config="dateConfig"
          timezone="Australia/Brisbane"
        />
      </v-menu>
    </v-col>
    <v-col cols="12" sm="4">
      <v-menu
        v-model="dateMenu4"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            v-model="form.sunset_at"
            label="Sunset Date"
            :append-icon="icons.calendar"
            clearable
            readonly
            outlined
            flat
          />
        </template>
        <v-date-picker
          ref="picker4"
          min="2020-01-01"
          v-model="form.sunset_at"
          @input="dateMenu4 = false"
        />
      </v-menu>
    </v-col>

    <v-col cols="12">
      <v-combobox
        v-model="form.features"
        label="Plan Features"
        :hide-no-data="!feature"
        :search-input.sync="feature"
        hide-selected
        small-chips
        multiple
        outlined
        flat
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.description"
        label="Plan Description"
        auto-grow
        outlined
        flat
      />
    </v-col>
    <v-col cols="12" mb-2>
      <v-line-subheader>Images</v-line-subheader>
      <v-divider title="Images"></v-divider>
    </v-col>
    <v-col cols="6" mb-2>
      <v-text-field
        v-model="form.image_public_id"
        label="Small Image"
        :error-messages="form.$getError('image_public_id')"
        auto-grow
        outlined
        flat
      />
    </v-col>
    <v-col cols="6" mb-2>
      <v-text-field
        v-model="form.secondary_image_public_id"
        label="Large Image"
        :error-messages="form.$getError('secondary_image_public_id')"
        auto-grow
        outlined
        flat
      />
    </v-col>
    <v-col cols="12" mb-2>
      <v-line-subheader>Video</v-line-subheader>
      <v-divider title="Video"></v-divider>
    </v-col>
    <v-col cols="12" mb-2>
      <v-text-field
        v-model="form.video_public_id"
        label="Video Path"
        :error-messages="form.$getError('video_public_id')"
        auto-grow
        outlined
        flat
      />
    </v-col>
    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
      <v-col cols="12" mb-2>
        <v-line-subheader>Primary Colour</v-line-subheader>
        <v-divider title="Primary Colour"></v-divider>
      </v-col>
      <v-color-picker
        :swatches="swatches"
        show-swatches
        hide-sliders
        v-model="form.primary_color"
      ></v-color-picker>
    </v-col>

    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
      <v-col cols="12" mb-2>
        <v-line-subheader>Icons</v-line-subheader>
        <v-divider title="Icons"></v-divider>
      </v-col>
      <plan-icons-list v-bind:value="form.icons" @input="form.icons = $event" />
    </v-col>
  </v-row>
</template>

<script>
import Form from '@/utils/form'

import { mdiCalendar } from '@mdi/js'
import { mask } from 'vue-the-mask'
import moment from 'moment-timezone'
import PlanIconsList from '../components/PlanIconsList'

export default {
  name: 'PlanDetailsForm',
  components: {
    PlanIconsList,
  },
  directives: {
    mask,
  },

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  data() {
    return {
      feature: null,
      form: this.formData,
      picker: false,

      dateMenu1: false,
      dateMenu2: false,
      dateMenu3: false,
      dateMenu4: false,

      icons: {
        calendar: mdiCalendar,
      },
      dateConfig: {
        type: 'string',
        mask: 'iso',
      },
      tzFormat: (date) => {
        return date
          ? moment(date)
              .tz('Australia/Brisbane')
              .format('lll')
          : null
      },
      swatches: [
        ['#FFF2DE', '#C7E0EC'],
        ['#FFCA90', '#258BED'],
        ['#EBD0BB', '#1E3D69'],
        ['#F5833E', '#245236'],
        ['#FF8080', '#000000'],
        ['#C5D7B1'],
      ],
    }
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },
}
</script>
